@import 'variables';

body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h1 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 26px;
  font-weight: 400;
}

h2 {
  font-size: 20px;
  font-weight: 400;
}

p {
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
  line-height: 1.4em;
}

a {
  text-decoration: none;
  color: blue;
  display: inline-block;
}

a:visited {
  color: blue;
}

a.external {
  background: transparent url('/assets/images/external.png') no-repeat center right;

  padding-right: 1em; /* sizing */
}

a:hover,
a:active {
  text-decoration: underline;
}

a:hover {
  cursor: pointer
}

strong {
  color: #222;
  font-weight: 600;
}

.hidden {
  display: none;
}

.note {
  font-style: italic;
  font-size: 13px;
}

@media (max-width: $medium-max-width) {
  .note-page-contents {
    padding-top: 20px;
    padding-bottom: 40px;
    padding-right: 20px;
  }
}

@media (min-width: $medium-min-width) {
  .note-page-contents {
    padding-top: 20px;
    padding-bottom: 40px;
    max-width: 70%;
  }
}

.kpn-warning {
  color: red !important;
}

.kpn-error {
  color: red;
  padding-top: 1em;
  padding-bottom: 1em;
}

.kpn-disabled, .kpn-disabled path, .kpn-disabled line, .kpn-disabled polyline {
  stroke: gray;
}

.kpn-link, .kpn-link path, .kpn-link line, .kpn-link polyline {
  color: blue !important;
}

.kpn-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.kpn-table th,
.kpn-table td {
  text-align: left;
  vertical-align: top;
  padding: 5px;
  line-height: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
}

.kpn-table th {
  font-weight: bold;
  color: #222;
  background-color: #eee;
}

table.kpn-columns-table {
  > thead > tr > th:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > tbody > tr > td:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

table.kpn-bordered-table {
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  > thead > tr > th {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px !important;
    padding-right: 10px !important;

    font-weight: bold;
    color: #222;
    background-color: #eee;
    vertical-align: top;
  }

  > tbody > tr > td {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.mat-mdc-list .mat-mdc-list-item {
  height: 32px;
}

.mat-mdc-outlined-button:not([disabled]) {
  background-color: white;
}

.kpn-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > :not(:last-child) {
    padding-right: 0.8em;
  }
}

.kpn-align-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.kpn-text-only-line {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  > :not(:last-child) {
    padding-right: 0.8em;
  }
}

.kpn-separated {
  > :not(:last-child) {
    padding-right: 0.8em;
  }
}

.kpn-separate {
  padding-left: 0.8em;
}

.kpn-detail {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.kpn-thick {
  display: inline;
  font-weight: bold;
}

.kpn-thin {
  display: inline;
  color: gray;

  a {
    color: rgba(0, 0, 255, 0.7);
  }
}

.kpn-level-1 {
  margin-top: 36px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: lightgray;
}

.kpn-level-1-header {
  @media (max-width: 768px) {
    // media.maxWidth(PageWidth.SmallMaxWidth.px)
    padding-bottom: 20px;
    font-size: 1.3em;
  }
  @media (min-width: 769px) {
    display: inline-block;
    font-size: 1.4em;
  }
}

.kpn-level-1-body {
  padding-top: 10px;
  padding-bottom: 20px;
}

.kpn-level-2 {
  margin: 0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.kpn-level-2-header {
  font-size: 1.1em;
  font-weight: bold;
  padding-bottom: 0.5em;
}

.kpn-level-2-body {
  @media (max-width: 768px) {
    // media.maxWidth(PageWidth.SmallMaxWidth.px)
    padding-bottom: 0.5em;
  }
  @media (min-width: 769px) {
    padding-left: 20px;
    padding-bottom: 0.5em;
  }
}

.kpn-level-3 {
  margin: 0; // TODO really needed?
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.kpn-level-3-header {
  padding-bottom: 0.5em;
}

.kpn-level-3-body {
  @media (max-width: 768px) {
    // media.maxWidth(PageWidth.SmallMaxWidth.px)
    padding-bottom: 0.5em;
  }
  @media (min-width: 769px) {
    padding-left: 20px;
    padding-bottom: 0.5em;
  }
}

.kpn-level-4 {
  // same as .kpn-detail
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.kpn-level-4-header {
  padding-bottom: 0.5em;
}

.kpn-level-4-body {
  @media (max-width: 768px) {
    // media.maxWidth(PageWidth.SmallMaxWidth.px)
    padding-left: 5px;
  }
  @media (min-width: 769px) {
    padding-left: 20px;
  }
}

.kpn-comma-list {
  display: inline-block;

  :not(:last-child):after {
    content: ',\00a0';
  }
}

.kpn-space-separated {
  display: inline-block;

  :not(:last-child):after {
    content: '\00a0';
  }
}

.kpn-brackets::before {
  content: '(';
  padding-left: 5px;
}

.kpn-brackets::after {
  content: ')';
}

.kpn-brackets-link a::before {
  content: '(';
  color: grey;
  padding-left: 5px;
}

.kpn-brackets-link a::after {
  content: ')';
  color: grey;
}

.kpn-label::after {
  content: ':\00a0';
}

.kpn-km::after {
  content: '\00a0km';
}

.kpn-meters::after {
  content: 'm';
}

.kpn-sentence::after {
  content: '.';
}

.kpn-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul.breadcrumb {
  list-style: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul.breadcrumb li {
  display: inline;
}

ul.breadcrumb li + li:before {
  padding: 0.3em;
  color: black;
  content: '>';
}

ul.links {
  list-style: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

ul.links li {
  display: inline;
}

ul.links li + li:before {
  padding: 0.3em;
  color: black;
  content: '|';
}

.sidebar-logo {
  background: rgba(254, 254, 254, 255) url('/assets/images/logo.svg') no-repeat center;
  background-position-y: 30px;
}

.chart {
  height: 300px;
}

.kpn-map {
  position: absolute;
  top: 180px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  overflow-y: hidden;
  overflow-x: hidden;
}

.kpn-map:-webkit-full-screen {
  top: 0;
}

.kpn-map:-ms-fullscreen {
  top: 0;
}

.kpn-map:fullscreen {
  top: 0;
}

.kpn-embedded-map {
  position: relative;
  left: 0;
  right: 20px;
  height: 320px;
  max-width: 640px;
  border: 1px solid lightgray;
  background-color: white;
}

.kpn-embedded-map:-webkit-full-screen {
  width: 100%;
  height: 100%;
  max-width: none;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

table.mat-mdc-table {
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  min-width: fit-content;
}

table.mat-mdc-table > thead > tr.mat-mdc-header-row,
table.mat-mdc-table > tbody > tr.mat-mdc-row,
table.mat-mdc-table > tfoot > tr.mat-mdc-footer-row {
  height: 2.5rem;
}

table.mat-mdc-table > thead > tr.mat-mdc-header-row > th.mat-mdc-header-cell,
table.mat-mdc-table > thead > tr.mat-mdc-header-row > th.mat-mdc-header-cell:first-of-type,
table.mat-mdc-table > tbody > tr.mat-mdc-row > td.mat-mdc-cell,
table.mat-mdc-table > tbody > tr.mat-mdc-row > td.mat-mdc-cell:first-of-type {
  //font-size: 16px;
  text-align: left;
  border-right: 1px solid lightgray;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

table.mat-mdc-table > thead > tr.mat-mdc-header-row > th.mat-mdc-header-cell {
  vertical-align: top;
  padding-top: 0.5rem;
  font-weight: bold;
  color: #222;
  background-color: #eee;
}

.kpn-spacer-above {
  margin-top: 2rem;
}

.kpn-spacer-below {
  margin-bottom: 2rem;
}

.kpn-small-spacer-above {
  margin-top: 1rem;
}

.kpn-small-spacer-below {
  margin-bottom: 1rem;
}

.kpn-tip {
  padding-left: 1rem;
  padding-right: 1rem;
  color: gray;
  font-style: italic;
}

.kpn-tip > li {
  color: gray;
  margin: 1rem;
}

markdown > p > em:after {
  content: '\0000a0';
}

.ol-attribution {
  font-size: 12px;
}

.kpn-button-group {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  column-gap: 1em;
}

.kpn-comment {
  line-height: 1.8em;
  max-width: 50em;
  padding-right: 3em;
}

.header-network-type-icon {
  display: inline-block;
  width: 1.6em;
  vertical-align: middle;
}

.header-network-type-icon mat-icon {
  width: 1.2em;
  height: 1.2em;
}

.kpn-form {
  padding-top: 1em;
}

.kpn-form-buttons {
  display: flex;
  padding-top: 2em;
  gap: 1em;
  align-items: center;
}

.kpn-form p {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.kpn-form-error {
  color: red;
  font-size: 1em;
  padding-bottom: 3em;
}

.kpn-action-cell {
  padding: 0 !important;
  min-width: 78px !important;
}

.kpn-action-button {
  transform: scale(0.7) !important;
  padding: 0 !important;
  width: 38px !important;
  height: 38px !important;
}

.kpn-action-button:disabled {
  pointer-events: auto;
}

// knooppuntnet specifics after Angular 15 update:
.mdc-form-field {
  height: 28px;
}

.poi-config .mat-mdc-radio-button .mdc-radio [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle, .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  opacity: 0.1 !important;
}

.mat-drawer-container {
  background-color: white;
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field {
  background-color: white;
}

.mdc-dialog__actions {
  padding-left: 1.5em !important;
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

/* hide up/down arrows on numeric input fields */

// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox
input[type=number] {
  -moz-appearance: textfield;
}

/* map controls */

.map-control {
  position: absolute;
  z-index: 100;
}

.map-control mat-icon {
  width: 14px;
  height: 14px;
}

.map-control-menu {
  min-width: 270px !important;
  overflow: hidden !important;
}

/* override geocoder control style */

.ol-geocoder .gcd-gl-control {
  background-color: rgba(0, 0, 0, 0);
}

.action-button-icon > svg {
  width: 14px !important;
  height: 14px !important;
  vertical-align: top !important;
  padding-top: 5px !important;
  color: blue;
}

.tryout-tabs .mat-mdc-tab-labels {
  flex-wrap: wrap;
  border-bottom: 1px solid lightgray;
}

.tryout-tabs .mat-mdc-tab-labels .mdc-tab:not(:last-child):after {
  content: '\00a0\00a0|\00a0\00a0';
}

.tryout-tabs .mat-mdc-tab.mdc-tab {
  height: 36px;
  padding-left: 0;
  padding-right: 0;
  min-width: 10px;
  letter-spacing: normal;
  font-weight: normal;
  font-size: 16px;
}

.tryout-tabs .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  font-weight: bold;
  color: black;
}

.tryout-tabs .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label {
  font-weight: bold;
  color: black;
}

.tryout-tabs .mat-mdc-tab .mdc-tab__text-label {
  color: blue;
}

.tryout-tabs-main-menu-option:after {
  content: '\00a0';
}

.menu-fit-width {
  max-width: fit-content !important;
}

.action-button-table-cell {
  padding-left: 0 !important;
  padding-right: 2em !important;
}

.planner-network-type-selector .mat-button-toggle-label-content {
  line-height: 34px !important;
  color: rgba(0, 0, 0, 0.8) !important;
  padding: 0 !important;
}
